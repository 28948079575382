var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{attrs:{"id":"center-management"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-10 pt-5"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h2',{staticClass:"d-flex"},[_c('v-icon',{on:{"click":function($event){return _vm.back()}}},[_vm._v("arrow_back_ios")]),_c('div',{staticClass:"d-flex align-center"},[_vm._v("Request a leave of absence")])],1)]),_c('div',{staticClass:"success"},[_c('v-alert',{staticStyle:{"font-size":"14px","padding":"12px"},attrs:{"value":_vm.checkSuccess,"transition":"scale-transition","type":"success"}},[_vm._v(" Create absence successfully ")])],1),_c('div',{staticClass:"pt-5"},[_c('v-textarea',{attrs:{"outlined":"","label":"Reason for leave of absence","value":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticStyle:{"background":"white"},attrs:{"type":"datetime-local","label":"From Time","outlined":"","hide-details":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('v-text-field',{staticStyle:{"background":"white"},attrs:{"type":"datetime-local","label":"To Time","outlined":"","hide-details":""},model:{value:(_vm.dateUntil),callback:function ($$v) {_vm.dateUntil=$$v},expression:"dateUntil"}}),_c('v-btn',{staticClass:"ml-3 mt-2",attrs:{"color":"primary","disabled":_vm.reason &&
                _vm._dayjs(_vm.dateFrom).isSameOrAfter(_vm.today, 'day') &&
                _vm._dayjs(_vm.dateUntil).isSameOrAfter(_vm.today, 'day') &&
                _vm._dayjs(_vm.dateUntil).isSameOrAfter(_vm.dateFrom)
                  ? false
                  : true},on:{"click":function($event){return _vm.request()}}},[_vm._v("Request")])],1),(
              _vm.reason &&
              _vm._dayjs(_vm.dateFrom).isSameOrAfter(_vm.today, 'day') &&
              _vm._dayjs(_vm.dateUntil).isSameOrAfter(_vm.today, 'day') &&
              _vm._dayjs(_vm.dateUntil).isSameOrAfter(_vm.dateFrom)
            )?_c('div',[_c('h3',{staticClass:"text-view pt-5 pb-5",on:{"click":function($event){return _vm.viewAbsence()}}},[_vm._v(" View my schedule "),_c('span',[_vm._v("from "+_vm._s(_vm._dayjs(_vm.dateFrom).format('DD/MM/YYYY, HH:mm')))]),_c('span',[_vm._v(" to "+_vm._s(_vm._dayjs(_vm.dateUntil).format('DD/MM/YYYY, HH:mm')))])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.lisAbsenceDetail,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
            var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._dayjs(item.date).format('YYYY-MM-DD'))+" ")]}}],null,true)})],1):_vm._e()])])],1),_c('v-footer',{staticClass:"pl-10 pr-10",attrs:{"fixed":"","absolute":"","height":"60","color":"#fff"}},[_c('v-row',[(_vm.showView === true)?_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1):_vm._e(),(_vm.showView === true)?_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"10"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-end"})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }